import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "pt-BR",
  numberFormats: {
    "pt-BR": {
      currency: {
        style: "currency",
        currency: "BRL",
      },
    },
  },
});

export default i18n;
