<template>
  <v-dialog
    scrollable
    :width="computedWidth"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :value="show"
    @click:outside="close"
  >
    <v-card :loading="loading">
      <identity v-if="show === 'identity'" @close="close" />

      <records
        v-if="show === 'records'"
        :loading.sync="loading"
        @close="close"
        @download="download"
      />

      <sales
        v-if="show === 'sales'"
        :loading.sync="loading"
        @close="close"
        @download="download"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import DownloadFile from "@/mixins/DownloadFile";
import Identity from "./Identity";
import Records from "./Records";
import Sales from "./Sales";

export default {
  name: "FiscalMenuDialog",
  mixins: [DownloadFile],
  components: {
    Identity,
    Records,
    Sales,
  },
  props: {
    value: [Object, String],
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    computedWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "80%";
        case "md":
          return "50%";
        case "lg":
          return "50%";
        case "xl":
          return "50%";
      }
      return "auto";
    },
    show() {
      if (typeof this.value === "string") {
        return this.value;
      }
      return this.value?.value;
    },
    title() {
      return this.value?.title || "";
    },
  },
  methods: {
    close() {
      this.$emit("input", null);
    },
    download(data) {
      this.downloadFile(data.filename, data.data);
      this.loading = false;
    },
  },
};
</script>
