<template>
  <v-autocomplete
    hide-no-data
    no-filter
    return-object
    item-text="description"
    item-value="id"
    :items="items"
    :label="label"
    :loading="loading"
    :search-input.sync="search"
    :value="product"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-content>
          <v-list-item-title
            class="font-weight-medium"
            v-html="genFilteredText(item.description)"
          />
          <v-list-item-subtitle v-if="showStock && item.stockAmount">
            <span class="secondary--text text--lighten-3">Disponível</span>
            <span class="pl-1">
              <number :value="item.stockAmount" />
              {{ item.unit }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action
          v-if="
            showPrice && ((showCostPrice && item.costPrice) || item.salePrice)
          "
        >
          <currency :value="showCostPrice ? item.costPrice : item.salePrice" />
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import Number from "@/components/Number";
import Currency from "@/components/Currency";

export default {
  name: "ProductField",
  components: {
    Number,
    Currency,
  },
  props: {
    label: { type: String, default: "Produto" },
    showCostPrice: Boolean,
    showPrice: Boolean, // price
    showStock: Boolean, // stock
    value: Object,
  },
  data() {
    return {
      loading: false,
      search: null,
      product: this.value,
      products: this.value ? [this.value] : [],
      requesting: null,
    };
  },
  computed: {
    items() {
      return this.products.map((p) => ({
        id: p.id,
        description: p.description,
        unit: p.unit,
        ncm: p.ncm,
        cest: p.cest,
        origin: p.origin,
        costPrice: p.cost_price,
        salePrice: p.sale_price,
        stockAmount: p.stocks?.find((s) => s.current_company)?.amount,
      }));
    },
  },
  watch: {
    value(v) {
      this.products = v ? [v] : [];
      this.product = v;
    },
    search(v) {
      if (v === this.product?.description) {
        return;
      }
      this.loading = true;

      if (this.requesting) {
        clearTimeout(this.requesting);
      }
      if (!v) {
        this.loading = false;
        return;
      }

      this.requesting = setTimeout(() => {
        this.$http
          .get(`/v1/products?q=${v}&per_page=50`)
          .then((res) => {
            this.products = res.data.items;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              return;
            }

            console.error(err);
            this.$sentry.captureException(err);
          })
          .then(() => {
            this.loading = false;
          });
      }, 300);
    },
  },
  methods: {
    genFilteredText(text) {
      text = text || "";

      if (!this.search) return text;

      const { start, middle, end } = this.getMaskedCharacters(text);
      return `${start}${this.genHighlight(middle)}${end}`;
    },
    getMaskedCharacters(text) {
      const searchInput = (this.search || "").toString().toLocaleLowerCase();
      const index = text.toLocaleLowerCase().indexOf(searchInput);

      if (index < 0) return { start: text, middle: "", end: "" };

      const start = text.slice(0, index);
      const middle = text.slice(index, index + searchInput.length);
      const end = text.slice(index + searchInput.length);
      return { start, middle, end };
    },
    genHighlight(text) {
      return `<span class="v-list-item__mask">${text}</span>`;
    },
  },
};
</script>
