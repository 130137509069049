<template>
  <div>
    <nav-bar
      :fiscal-menu.sync="fiscalMenu"
      @change:company="companySelected"
      @click:drawer="drawer = true"
    />

    <company-select :value="selectCompany" @change="companySelected" />

    <side-bar :fiscal-menu.sync="fiscalMenu" v-model="drawer" />

    <v-main>
      <slot />

      <notification-bar />
      <fiscal-dialog v-model="fiscalMenu" />
    </v-main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavBar from "@/components/NavBar";
import CompanySelect from "@/components/CompanySelect";
import SideBar from "@/components/SideBar";
import NotificationBar from "@/components/NotificationBar";
import FiscalDialog from "@/components/FiscalMenu/Dialog";

export default {
  components: {
    NavBar,
    CompanySelect,
    SideBar,
    NotificationBar,
    FiscalDialog,
  },
  data: () => ({
    drawer: false,
    fiscalMenu: null,
  }),
  computed: {
    selectCompany() {
      return !this.company;
    },
    ...mapState("auth", ["company"]),
  },
  methods: {
    companySelected(company) {
      if (!company) return;
      this.$store.commit("auth/company", company);
    },
  },
};
</script>
