<script>
export default {
  name: "Logout",
  mounted() {
    this.$store.dispatch("auth/logout");
    this.$http.post("/v1/auth/session/destroy").finally(() => {
      this.$router.push({ name: "login", query: this.$route.query });
    });
  },
  render() {},
};
</script>
