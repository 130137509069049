import Vue from "vue";
import App from "./App.vue";
import i18n from "./plugins/i18n";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "./plugins/api";
import "./plugins/axios";
import "./plugins/currency-input";
import "./plugins/localstorage";
import "./plugins/moment";
import "./plugins/sentry";
import "./plugins/vue-meta";
import "./plugins/vuelidate";

import DefaultLayout from "./layouts/Default";
import AppLayout from "./layouts/App";

import { notificationMixin } from "./mixins/notificationMixin";

Vue.component("default-layout", DefaultLayout);
Vue.component("app-layout", AppLayout);

Vue.mixin(notificationMixin);

if (process.env.NODE_ENV === "production") {
  Vue.config.productionTip = false;
  Vue.config.devtools = false;
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
