import Vue from "vue";
import $http from "@/plugins/axios";
import Customers from "@/api/customers";
import NFes from "@/api/nfes";
import Products from "@/api/products";

const repositories = {
  customers: Customers($http),
  nfes: NFes($http),
  products: Products($http),
};

Vue.prototype.$api = repositories;

export default repositories;
