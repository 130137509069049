<template>
  <v-text-field
    type="text"
    :value="display"
    v-bind="$attrs"
    v-mask="config"
    v-on="listeners"
    @input.native="onInput"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
import { mask, tokens } from "vue-the-mask";
import masker from "vue-the-mask/src/masker";

export default {
  name: "MaskTextField",
  props: {
    mask: { type: [String, Array], required: true },
    // by default emits the value unformatted, change to true to format with the mask
    masked: { type: Boolean, default: false },
    tokens: { type: Object, default: () => tokens },
    value: [String, Number],
  },
  directives: { mask },
  data() {
    return {
      lastValue: null, // avoid unecessary emit when has no change
      display: this.value,
    };
  },
  watch: {
    value(newValue) {
      if (!this.masked) {
        newValue = masker(newValue, this.mask, true, this.tokens);
      }
      if (newValue !== this.lastValue) {
        this.display = newValue;
      }
    },
    masked() {
      this.refresh(this.display);
    },
  },
  computed: {
    config() {
      return {
        mask: this.mask,
        tokens: this.tokens,
        masked: this.masked,
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        change: this.onChange,
      };
    },
  },
  methods: {
    onInput(e) {
      if (e.isTrusted) return; // ignore native event
      this.refresh(e.target.value);
    },
    onChange() {
      this.$emit("change", this.lastValue);
    },
    refresh(v) {
      this.display = v;
      var value = masker(v, this.mask, this.masked, this.tokens);
      if (value !== this.lastValue) {
        this.lastValue = value;
        this.$emit("input", value);
      }
    },
  },
};
</script>
