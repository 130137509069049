import app from "./modules/app";
import auth from "./auth";
import notafiscal from "./notafiscal";
import nfes from "./modules/nfes";
import notification from "./modules/notification";
import products from "./modules/products";
import purchase from "./purchase";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    app,
    auth,
    nfes,
    notafiscal,
    notification,
    products,
    purchase,
    systemNotification: notification,
  },
});
