<script>
import Decimal from "decimal.js";

export default {
  name: "Rules",
  methods: {
    notEmptyRule: (v) =>
      !!v || v === 0 || v === false || "Campo não pode ser vazio",
    lengthRule(length) {
      return (v) =>
        !v || v.length == length || "Número de caracteres não aceito";
    },
    minLengthRule(length) {
      return (v) =>
        !v || v.length >= length || `Mínimo de ${length} caracteres aceito`;
    },
    maxLengthRule(length) {
      return (v) =>
        !v || v.length <= length || `Máximo de ${length} caracteres aceito`;
    },
    minMaxLengthRule(min, max) {
      return (v) =>
        !v ||
        (v.length >= min && v.length <= max) ||
        "Número de caracteres não aceito";
    },
    minDecimalRule(min, v) {
      return (v2) => {
        if (!v && (v2 === "0" || v2 === "0,00")) {
          v2 = 0;
        }
        let vdec = new Decimal(v || v2 || 0);
        return (
          vdec.greaterThanOrEqualTo(min) || `Valor precisa ser maior que ${min}`
        );
      };
    },
    maxDecimalRule(max, v) {
      return (v2) => {
        if (!v && (v2 === "0" || v2 === "0,00")) {
          v2 = 0;
        }
        let vdec = new Decimal(v || v2 || 0);
        return (
          vdec.lessThanOrEqualTo(max) || `Valor precisa ser menor que ${max}`
        );
      };
    },
  },
};
</script>
