<template>
  <v-dialog
    persistent
    scrollable
    :width="width"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="value"
  >
    <v-card :loading="!companiesLoaded">
      <v-card-title class="text-button">
        Qual filial você deseja se logar?
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-card-title v-if="!companiesLoaded" class="text-body-2">
          <v-row>
            <v-col>
              Carregando filiais...
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-title v-else-if="companies.length === 0">
          <v-row>
            <v-col cols="auto">
              <v-icon color="warning">mdi-alert</v-icon>
            </v-col>

            <v-col class="text-body-2" style="word-break: break-word;">
              Você não tem acesso a nenhuma filial, consulte o seu Gerente.
            </v-col>

            <v-col cols="auto">
              <v-btn text color="primary" to="/logout">
                <v-icon>mdi-logout</v-icon>
                Sair
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-list v-else>
          <v-list-item-group
            no-action
            color="primary"
            v-model="companyId"
            @change="changed"
          >
            <v-list-item
              two-line
              v-for="(company, i) in companies"
              :key="i"
              :value="company.id"
            >
              <v-list-item-avatar>
                <v-icon>
                  mdi-office-building-marker
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="company.name" />
                <v-list-item-subtitle class="secondary--text text--lighten-2">
                  {{ company.cnpj | cnpj }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import FormatterFilter from "@/mixins/FormatterFilter";

export default {
  name: "CompanySelect",
  mixins: [FormatterFilter],
  props: {
    value: Boolean,
  },
  data: () => ({
    companyId: null,
  }),
  computed: {
    companiesLoaded() {
      return this.companies !== null;
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "65%";
        case "md":
          return "41%";
        case "lg":
          return "31%";
        case "xl":
          return "30%";
      }
      return "auto";
    },
    ...mapState("auth", ["companies"]),
  },
  methods: {
    changed() {
      if (!this.companyId) return;

      const company = this.companies.find((c) => this.companyId == c.id);
      if (company) {
        this.$emit("change", company);
      }
    },
  },
};
</script>
