export const notificationMixin = {
  methods: {
    notifyError(text, timeout) {
      this.$store.dispatch("notification/error", { text, timeout });
    },
    notifySuccess(text, timeout) {
      this.$store.dispatch("notification/success", { text, timeout });
    },
  },
};
