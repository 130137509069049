<template>
  <v-snackbar
    bottom
    left
    multi-line
    :color="color"
    :timeout="timeout"
    :value="show"
    :vertical="$vuetify.breakpoint.xsOnly || (hasAction && closeable)"
    @input="dismiss"
  >
    <span v-html="text" />
    <template v-if="hasAction || closeable" v-slot:action="{ attrs }">
      <v-btn
        v-if="hasAction"
        exact
        text
        :to="actionTo"
        v-bind="attrs"
        v-text="actionText"
        @click="clicked('action', $event)"
      />
      <v-btn
        v-if="closeable"
        text
        v-bind="attrs"
        @click="clicked('close', $event)"
      >
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SystemNotification",
  computed: {
    ...mapState("systemNotification", [
      "action",
      "closeable",
      "color",
      "text",
      "timeout",
    ]),
    ...mapGetters("systemNotification", ["show"]),
    hasAction() {
      return !!this.action;
    },
    actionTo() {
      const { to } = this.action;
      return to;
    },
    actionText() {
      if (typeof this.action === "string") return this.action;
      const { text } = this.action;
      return text;
    },
  },
  methods: {
    clicked(button, e) {
      this.$emit(`click:${button}`, e);
      this.dismiss();
    },
    dismiss() {
      this.$store.dispatch("systemNotification/dismiss");
    },
  },
};
</script>

<style lang="sass" scoped>
.v-snack.v-snack--active
  @media #{map-get($display-breakpoints, 'xs-only')}
    padding-bottom: 22px !important
</style>
