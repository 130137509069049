import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  environment: window.ENVIRONMENT,
  release: "gioxnfe@" + window.VERSION,
  dsn: window.SENTRY_DSN,
  tracesSampleRate: 1.0,
  integrations: [new Integrations.BrowserTracing()],
  tracingOptions: {
    trackComponents: true,
  },
  sampleRate: 1.0,
  logErrors: true,

  beforeSend(event, hint) {
    const err = hint.originalException;
    return discardError(err?.message) ? null : event;
  },
});

function discardError(err) {
  if (!err) return false;

  const knownErrors = [
    /Navigation cancelled from /,
    /Avoided redundant navigation to current location:/,
    /Network Error/,
    /Redirected when going from "[\w/-_]+" to "[\w/-_]+" via a navigation guard./,
  ];

  for (const regex of knownErrors) {
    if (err.match(regex)) return true;
  }
  return false;
}

Vue.prototype.$sentry = Sentry;

export default Sentry;
