import store from "@/store";
import http from "@/plugins/axios";
import moment from "@/plugins/moment";
import sentry from "@/plugins/sentry";
import { HttpError } from "@/components/Error";

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    items: [],
  }),
  getters: {
    items: (state) => state.items,
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setItems(state, items) {
      state.items = items;
    },
  },
  actions: {
    async fetch({ commit }, criteria) {
      commit("setLoading", true);

      const { month } = criteria;
      const startDate = moment(month).format("YYYY-MM-DD");
      const endDate = moment(month)
        .add(1, "months")
        .subtract(1, "seconds")
        .format("YYYY-MM-DD");

      let params = new URLSearchParams({
        imported_at: `${startDate}..${endDate}`,
        per_page: -1,
      });

      try {
        const { data } = await http.get(`/v1/purchases?${params}`);
        commit("setItems", data.items);
      } catch (err) {
        commit("setItems", []);

        sentry.captureException(err);
        store.dispatch(
          "notification/error",
          "Ocorreu um erro ao carregar as Notas Fiscais Recebidas 😢"
        );
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchById(context, id) {
      try {
        const { data } = await http.get(`/v1/purchases/${id}`);
        return data;
      } catch (err) {
        if (err instanceof HttpError) {
          switch (err.code) {
            case "not_found":
              store.dispatch(
                "notification/error",
                `Nota Fiscal não encontrada`
              );
              break;
            default:
              sentry.captureException(err);
              store.dispatch(
                "notification/error",
                "Ocorreu um erro ao carregar a Nota Fiscal 😢"
              );
          }
        } else {
          console.error(err);
          sentry.captureException(err);
        }
        throw err;
      }
    },
  },
};
