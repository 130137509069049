import http from "@/plugins/axios";
import sentry from "@/plugins/sentry";
import { HttpError } from "@/components/Error";

export default {
  async getAddress(zipcode, country = "BR") {
    try {
      const { data } = await http.get(`/v1/zipcodes/${country}/${zipcode}`);
      return data;
    } catch (err) {
      if (err instanceof HttpError) {
        throw err;
      } else {
        // it was already handled by axios
        throw null;
      }
    }
  },

  async getCountryStates(country) {
    try {
      const { data } = await http.get(`/v1/countries/${country}/states`);
      return data;
    } catch (err) {
      sentry.captureException(err);
    }
  },
};
