import Vue from "vue";
import VueMoment from "vue-moment";
import moment from "moment";

moment.locale("pt-br");

Vue.use(VueMoment, {
  moment,
});

export default moment;
