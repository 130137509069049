import http from "@/plugins/axios";
import sentry from "@/plugins/sentry";

export default {
  async getCESTs() {
    try {
      const { data } = await http.get("/v1/fiscal/cests");
      return data;
    } catch (err) {
      sentry.captureException(err);
    }
    return {
      items: [],
    };
  },
  async getCFOPs() {
    try {
      const { data } = await http.get("/v1/fiscal/cfops");
      return data;
    } catch (err) {
      sentry.captureException(err);
    }
    return {
      items: [],
    };
  },
  async getNCMs() {
    try {
      const { data } = await http.get("/v1/fiscal/ncms");
      return data;
    } catch (err) {
      sentry.captureException(err);
    }
    return {
      items: [],
    };
  },
};
