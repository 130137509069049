<template>
  <v-footer app padless absolute>
    <v-card flat tile class="text-center" width="100vw">
      <v-card-text>
        <v-btn
          icon
          class="mx-4"
          target="_blank"
          :href="link.href"
          :key="link.icon"
          v-for="link in links"
        >
          <v-icon size="24">{{ link.icon }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <strong class="primary--text">Giox Tecnologia</strong> &copy;
        {{ new Date().getFullYear() }}.
        <span class="d-block d-sm-inline-block">
          Todos os direitos reservados.
        </span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "FooterBar",
  data: () => ({
    links: [
      { icon: "mdi-home", href: "https://giox.com.br" },
      { icon: "mdi-help-circle", href: "http://suporte.gioxnfe.com.br" },
      { icon: "mdi-facebook", href: "https://facebook.com/gioxtech" },
      { icon: "mdi-youtube", href: "https://youtube.com/gioxtech" },
      {
        icon: "mdi-twitter",
        href: "https://twitter.com/intent/follow?screen_name=gioxtech",
      },
    ],
  }),
};
</script>
