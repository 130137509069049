<template>
  <v-snackbar
    top
    right
    multi-line
    :value="show"
    :color="color"
    :timeout="timeout"
    :vertical="$vuetify.breakpoint.xsOnly || (hasAction && closeable)"
    @input="dismiss"
  >
    <span v-html="text" />
    <template
      v-if="hasAction || closeable"
      v-slot:action="{ attrs }"
    >
      <v-btn
        v-if="hasAction"
        text
        exact
        :to="action.to"
        v-bind="attrs"
        v-text="action.text"
        @click="dismiss"
      />
      <v-btn
        v-if="closeable"
        text
        v-bind="attrs"
        @click="dismiss"
      >
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "NotificationBar",
  computed: {
    ...mapState("notification", [
      "color",
      "text",
      "timeout",
      "closeable",
      "action",
    ]),
    ...mapGetters("notification", ["show"]),
    hasAction() {
      return !!this.action && this.action.to !== this.$route.fullPath;
    },
  },
  methods: {
    dismiss() {
      this.$store.dispatch("notification/dismiss");
    },
  },
};
</script>
