import Vue from "vue";
import { HttpError } from "@/components/Error";
import axios from "axios";
import Sentry from "@/plugins/sentry";
import store from "@/store";

const http = axios.create({
  baseURL: window.API_URL,
  withCredentials: true,
});

function createHttpError(response, data) {
  const err = new HttpError(
    response.status,
    data.code,
    data.message,
    data.field
  );
  console.error("http error: ", err);
  return err;
}

http.interceptors.response.use(null, async (err) => {
  if (err.config) {
    const { baseURL, data, headers, method, url } = err.config;
    Sentry.configureScope((scope) => {
      scope.setContext("HTTP Request", {
        baseURL,
        data,
        headers,
        method,
        url: baseURL + url,
      });
    });
  }

  if (err.response) {
    const { data, headers, status } = err.response;

    if (status === 401) {
      // FIXME: when app load we check if the user has a valid token
      // in case not, server will return 401 and this will be in a loop
      // location.reload();
      // return;
    }

    Sentry.configureScope((scope) => {
      scope.setContext("HTTP Response", {
        data: JSON.stringify(data),
        headers,
        status,
        Error: err.message,
      });
      scope.setExtra("Request Id", err.response.headers["x-request-id"]);
    });

    if (err.response.data instanceof Blob) {
      if (err.response.data.type === "application/json") {
        err.response.data = JSON.parse(await err.response.data.text());
      }
    }

    if (err.response.data) {
      if (err.response.data.code) {
        const httpErr = createHttpError(err.response, err.response.data);
        return Promise.reject(httpErr);
      }
      if (err.response.data.error?.code) {
        const httpErr = createHttpError(err.response, err.response.data.error);
        return Promise.reject(httpErr);
      }
    }

    // it's not an API error.
    console.error(err);
    Sentry.captureException(err);

    store.dispatch(
      "notification/error",
      "Oopsie! Não conseguimos se conectar com o servidor 😭"
    );
  }

  return Promise.reject(err);
});

Vue.prototype.$http = http;

export default http;
