<script>
import { HttpError } from "@/components/Error";

export default {
  name: "DownloadFile",
  methods: {
    downloadFile(filename, data, mimeType) {
      const url = window.URL.createObjectURL(new Blob([data]), {
        type: mimeType,
      });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
    async downloadFileWhenReady(url, filename, id) {
      try {
        const { data, headers } = await this.$http.get(url, {
          responseType: "blob",
        });
        this.downloadFile(filename, data, headers["content-type"]);
      } catch (err) {
        if (err instanceof HttpError) {
          if (err.code === "not_ready") {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await this.downloadFileWhenReady(url, filename, id);
            return;
          }
          this.$sentry.captureException(err);
          this.notifyError("Ocorreu um erro ao efetuar o Download 😢");
          return;
        }
        console.error(err);
        this.$sentry.captureException(err);
      }
    },
  },
};
</script>
