<template>
  <v-container :fluid="$vuetify.breakpoint.mdOnly">
    <v-row>
      <v-col>
        <v-img
          contain
          height="114"
          position="left"
          src="/img/logo-slogan.png"
        />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" xl="6" lg="6" md="8" sm="8">
        <v-card-text class="text-center">
          <div class="text-h1">404</div>
          <div class="text-h5 font-weight-thin">Página não encontrada</div>
        </v-card-text>

        <v-card-text>
          A página que você está tentando acessar não existe. Talvez ela já
          tenha existido no passado, ou ocorreu algum erro em nossos servidores.
          Se você acredita que esta página deveria sim existir, entre em contato
          com nosso suporte
          <a href="http://suporte.gioxnfe.com.br/" target="_blank">
            clicando aqui
          </a>
          .
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  metaInfo() {
    return {
      title: "Página não encontrada",
    };
  },
};
</script>
