<template>
  <div class="d-flex flex-column flex-grow-1">
    <v-card-title class="text-button">
      Vendas Identificadas pelo CPF/CNPJ
    </v-card-title>

    <v-divider />

    <v-card-text class="flex-grow-1">
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="4">
              <date-picker-field
                hide-details
                label="Periodo"
                type="month"
                :max="today"
                :rules="[notEmptyRule]"
                v-model="date"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto">
              <v-switch
                hide-details
                label="Especificar CPF/CNPJ"
                v-model="withCustomer"
              />
            </v-col>

            <v-col v-if="withCustomer" cols="12" sm="6">
              <mask-text-field
                hide-details="auto"
                label="CPF / CNPJ"
                :mask="['###.###.###-##', '##.###.###/####-##']"
                :rules="[minMaxLengthRule(11 + 3, 14 + 4)]"
                v-model="customer"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions class="pb-4 justify-center">
      <v-btn depressed @click="$emit('close')">
        Fechar
      </v-btn>

      <v-btn color="success" :loading="loading" @click="download">
        <v-icon left>mdi-content-save-outline</v-icon>
        Download
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DownloadFile from "@/mixins/DownloadFile";
import Rules from "@/mixins/Rules";
import MaskTextField from "@/components/MaskTextField";
import DatePickerField from "@/components/DatePickerField.vue";

export default {
  name: "FiscalMenuSales",
  mixins: [DownloadFile, Rules],
  components: {
    MaskTextField,
    DatePickerField,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 7),
      withCustomer: false,
      customer: "",
    };
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    format() {
      return this.company?.address.city.state;
    },
    ...mapState("auth", ["company"]),
  },
  methods: {
    async download() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("update:loading", true);

      const startDate = this.$moment(this.date);
      const endDate = startDate
        .clone()
        .add(1, "months")
        .subtract(1, "seconds");
      let period =
        startDate.format("YYYY-MM-DD") + ".." + endDate.format("YYYY-MM-DD");

      try {
        const { data } = await this.$http.post("/v1/fiscal/files/sales", [], {
          params: {
            format: this.format,
            period,
            customer: this.withCustomer ? this.customer : undefined,
          },
        });

        const url = `/v1/fiscal/files/sales/${data.id}`;
        const filename = `${this.company.cnpj}-vendas.txt`;
        await this.downloadFileWhenReady(url, filename, data.id);
      } catch (err) {
        console.error(err);
        this.$sentry.captureException(err);

        this.notifyError("Ocorreu um erro ao efetuar o Download 😢");
      } finally {
        this.$emit("update:loading", false);
      }
    },
  },
};
</script>
