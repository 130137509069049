<script>
import { tokens } from "vue-the-mask";
import masker from "vue-the-mask/src/masker";

function cpfFilter(value) {
  if (!value) return "";
  return masker(value, "###.###.###-##", true, tokens);
}

function cnpjFilter(value) {
  if (!value) return "";
  return masker(value, "##.###.###/####-##", true, tokens);
}

function internationPhoneMask(value) {
  if (value.startsWith("+")) {
    value = value.substring(1);
  }

  const countryCode = value.substring(0, 2);
  switch (countryCode) {
    case "55":
      return brPhoneMask(value);
    case "49":
      return dePhoneMask(value);
  }
}

function brPhoneMask(value) {
  switch (value.length) {
    case 10:
      return "(##) ####-####";
    case 11:
      return "(##) #####-####";
    case 12:
      return "+## (##) ####-####";
    case 13:
      return "+## (##) #####-####";
  }
}

function dePhoneMask(value) {
  switch (value.length) {
    case 12:
      return "+## (###) ####-###";
  }
}

export default {
  name: "FormatterFilter",
  filters: {
    cpf: cpfFilter,
    cnpj: cnpjFilter,
    cpfCnpj(value) {
      if (!value) return "";
      if (value.length === 11) return cpfFilter(value);
      if (value.length === 14) return cnpjFilter(value);
      return value;
    },
    zipcode: function(value) {
      if (!value) return "";
      return masker(value, "#####-###", true, tokens);
    },
    phone: function(value) {
      if (!value) return "";

      var mask;

      if (value.startsWith("+")) {
        mask = internationPhoneMask(value);
      } else {
        mask = brPhoneMask(value);
      }

      if (!mask) return value;
      return masker(value, mask, true, tokens);
    },
    sefazId: function(value) {
      if (!value) return "";
      if (value.length != 44) return "";
      return masker(
        value,
        "#### #### #### #### #### #### #### #### #### #### ####",
        true,
        tokens
      );
    },
  },
};
</script>
