import { Workbox } from "workbox-window";
import Sentry from "@/plugins/sentry";

const workbox = new Workbox("/service-worker.js");

const isServiceWorkerSupported = () => "serviceWorker" in navigator;

const registerToServicerWorker = () => {
  if (isServiceWorkerSupported()) {
    if (process.env.NODE_ENV === "production") {
      workbox
        .register()
        .then(() => {
          console.log("Service Worker registration completed");
        })
        .catch((err) => {
          console.error("Service Worker registration failed:", err);
          Sentry.captureException(err);
        });
    } else {
      console.log("Service Worker is NOT registered in this environment");
    }
  }
};

const sendMessageToServiceWorker = (message) => {
  return new Promise((resolve, reject) => {
    workbox.messageSW(message).then((event) => {
      if (event.data) {
        if (event.data.error) {
          reject(event.data.error);
        } else {
          resolve(event.data);
        }
      }
    });
  });
};

export {
  workbox,
  isServiceWorkerSupported,
  registerToServicerWorker,
  sendMessageToServiceWorker,
};
