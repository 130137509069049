<template>
  <v-list :nav="nav" :dense="dense">
    <div v-for="(item, i) in items" :key="i">
      <v-subheader v-if="item.header" v-text="item.header" />
      <v-divider v-else-if="item.divider" />

      <v-list-item
        v-else
        active-class="primary--text"
        :class="item.class"
        :disabled="item.disabled"
        :to="item.to"
        v-on="events(item)"
      >
        <v-list-item-icon v-if="item.icon">
          <v-icon v-text="item.icon" />
        </v-list-item-icon>

        <v-list-item-content>
          <template v-if="!!item.subtitle">
            <v-list-item-title v-text="item.title" />
            <v-list-item-subtitle v-text="item.subtitle" />
          </template>
          <span v-else v-text="item.title" />
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
export default {
  name: "List",
  props: {
    nav: Boolean,
    dense: Boolean,
    returnObject: Boolean,
    items: Array,
  },
  methods: {
    events(item) {
      if (!item.value) {
        return {};
      }
      return {
        ...item.listeners,
        click: () => {
          const v = this.returnObject ? item : item.value;
          this.$emit("click:item", v);
          this.$emit(`click:${item.value}`);
          if (item.listeners?.click) {
            item.listeners.click(v);
          }
        },
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.v-list-item.v-list-item--disabled
  .v-list-item__icon.v-icon
    color: inherit !important

  .v-list-item__subtitle
    color: inherit !important
</style>
