export const systemNotificationMixin = {
  methods: {
    notifyNewVersionAvailable() {
      this.$store.dispatch("systemNotification/show", {
        text: "Uma nova versão do sistema está disponível",
        timeout: -1,
        closeable: false,
        action: "Atualizar",
      });
    },
  },
};
