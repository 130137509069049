<template>
  <v-app>
    <component :is="layout">
      <v-progress-linear indeterminate height="5" :active="loading" />
      <router-view />
    </component>

    <footer-bar />
    <system-notification @click:action="updateServiceWorker" />
  </v-app>
</template>

<script>
import { HttpError } from "@/components/Error";
import { mapState } from "vuex";
import { systemNotificationMixin } from "@/mixins/systemNotificationMixin";
import {
  workbox,
  sendMessageToServiceWorker,
  registerToServicerWorker,
} from "@/plugins/workbox";
import FooterBar from "@/components/FooterBar";
import SystemNotification from "@/components/SystemNotification";

export default {
  name: "App",
  metaInfo: {
    titleTemplate(title) {
      let appName = "Giox NF-e";
      if (!title) {
        return appName;
      }
      return title + " | " + appName;
    },
  },
  mixins: [systemNotificationMixin],
  components: {
    SystemNotification,
    FooterBar,
  },
  data: () => ({
    refreshTimeout: null,
  }),
  computed: {
    layout() {
      if (this.$route.matched.length && this.$route.meta.layout) {
        return `${this.$route.meta.layout}-layout`;
      }
      return "default-layout";
    },
    sentryUser() {
      if (!this.tenant || !this.user) {
        return null;
      }
      return {
        tenant: this.tenant,
        id: this.user.id,
        username: this.user.username,
        email: this.user.username + "@" + this.tenant,
        companyId: this.company?.id,
        companyCnpj: this.company?.cnpj,
      };
    },
    ...mapState({
      loading: (state) => state.app.loading,
      displayMode: (state) => state.app.displayMode,
    }),
    ...mapState("auth", ["tokenExpiresAt", "tenant", "user", "company"]),
  },
  watch: {
    tenant(v) {
      this.$sentry.setTag("tenant", v);
    },
    async "user.id"(v) {
      if (!v) return;

      try {
        const { data } = await this.$http.get("/v1/companies");
        this.$store.commit("auth/companies", data.items);
      } catch (err) {
        console.error(err);
        this.$sentry.captureException(err);
      }
    },
    tokenExpiresAt(v) {
      if (this.refreshTimeout) {
        clearTimeout(this.refreshTimeout);
      }
      if (!v) return;

      let now = this.$moment();
      let diff = this.$moment(v).diff(now);
      let refreshInMs = diff / 2;

      console.log(`Scheduling token refresh in ${refreshInMs}ms`);
      this.refreshTimeout = setTimeout(() => {
        this.refreshToken();
      }, refreshInMs);
    },
    "company.id"(v) {
      this.$sentry.setTag("company.id", v);

      if (!v) {
        delete this.$http.defaults.headers.common["X-Company-Id"];
        return;
      }

      this.$localStorage.set(`${this.tenant}:company`, v);
      this.$http.defaults.headers.common["X-Company-Id"] = v;
      // reload company detail
      this.userInfo();
    },
    "company.cnpj"(v) {
      this.$sentry.setTag("company.cnpj", v);
    },
    sentryUser(v) {
      this.$sentry.setUser(v);
    },
    displayMode(v) {
      this.$sentry.setTag("display_mode", v);
    },
  },
  methods: {
    async userInfo() {
      try {
        const { data } = await this.$http.get("/v1/auth/user");
        this.$store.commit("auth/user", data);
        if (data.company) {
          this.$store.commit("auth/company", data.company);
        }
      } catch (err) {
        this.$store.commit("auth/user", null);
        return;
      }
    },
    async refreshToken() {
      console.log("Refreshing access token...");

      try {
        const { data } = await this.$http.post("/v1/auth/refresh");
        this.$store.commit("auth/user", data);
        if (data.company) {
          this.$store.commit("auth/company", data.company);
        }
      } catch (err) {
        this.$store.commit("auth/user", null);

        if (err instanceof HttpError) {
          switch (err.code) {
            case "jwt.expired_token":
              break;
            default:
              this.$sentry.captureException(err);
          }
        } else {
          console.error(err);
          this.$sentry.captureException(err);
        }
      }
    },
    updateServiceWorker() {
      sendMessageToServiceWorker({ type: "SKIP_WAITING" });
    },
    updateDisplayMode(matchMedia) {
      const isStandalone = matchMedia.matches;
      if (!isStandalone && this.displayMode == "standalone") {
        const { matches } = window.matchMedia("(display-mode: fullscreen)");
        if (matches) {
          // If app goes to fullscreen doesn't change displayMode
          return;
        }
      }

      let displayMode;
      if (document.referrer.startsWith("android-app://")) {
        displayMode = "twa";
      } else if (navigator.standalone || isStandalone) {
        displayMode = "standalone";
      } else {
        displayMode = "browser";
      }
      this.$store.dispatch("setDisplayMode", displayMode);
    },
  },
  async created() {
    workbox.addEventListener("waiting", () => {
      this.notifyNewVersionAvailable();
      workbox.addEventListener("controlling", () => {
        window.location.reload();
      });
    });
    registerToServicerWorker();

    const matchMedia = window.matchMedia("(display-mode: standalone)");
    matchMedia.addEventListener("change", this.updateDisplayMode);
    this.updateDisplayMode(matchMedia);

    // Read localStorage and prepare to /auth/user call
    const tenant = this.$localStorage.get("tenant");
    const companyId = this.$localStorage.get(`${tenant}:company`);
    if (companyId) {
      this.$http.defaults.headers.common["X-Company-Id"] = companyId;
    }

    this.userInfo();
  },
};
</script>

<style lang="sass">
.toolbar
  .v-btn--outlined, .v-btn--text
    height: 48px !important

.v-card__title
  &.section-title, .section-title
    // primary--text
    color: var(--v-primary-base) !important
    // text-button
    font-size: 0.875rem !important
    line-height: 2.25rem
    letter-spacing: 0.0892857143em !important
    font-family: "Roboto", sans-serif !important
    text-transform: uppercase !important
    // font-weight-bold
    font-weight: 700 !important
</style>
