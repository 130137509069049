<template>
  <div>
    <span v-if="text">
      {{ $attrs["value"] | cpfCnpj }}
    </span>

    <mask-text-field
      v-else
      :label="label"
      :placeholder="placeholder"
      :mask="mask"
      :rules="rules"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import FormatterFilter from "@/mixins/FormatterFilter";
import MaskTextField from "@/components/MaskTextField";

export default {
  name: "CpfCnpjField",
  mixins: [FormatterFilter],
  components: {
    MaskTextField,
  },
  props: {
    cnpjLabel: { type: String, default: "CNPJ" },
    cnpjRules: {
      type: [Array, Function],
      default: () => [
        (v) => (v || "").length == 14 + 4 || "CNPJ não parece válido",
      ],
    },
    cpfLabel: { type: String, default: "CPF" },
    cpfRules: {
      type: [Array, Function],
      default: () => [
        (v) => (v || "").length == 11 + 3 || "CPF não parece válido",
      ],
    },
    text: Boolean,
    type: String,
  },
  computed: {
    isCompany() {
      return this.type === "company";
    },
    label() {
      return this.isCompany ? this.cnpjLabel : this.cpfLabel;
    },
    placeholder() {
      return this.isCompany ? "00.000.000/0000-00" : "000.000.000-00";
    },
    mask() {
      return this.isCompany ? "##.###.###/####-##" : "###.###.###-##";
    },
    rules() {
      return this.isCompany ? this.cnpjRules : this.cpfRules;
    },
  },
};
</script>
