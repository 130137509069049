const DEFAULT_TIMEOUT = 6000;

export default {
  namespaced: true,
  state() {
    return {
      color: "primary",
      text: null,
      timeout: DEFAULT_TIMEOUT,
      closeable: true,
      action: null,
    };
  },
  getters: {
    show: (state) => !!state.text,
    actionTo: (state) => state.action?.to || null,
  },
  mutations: {
    SET_COLOR(state, color) {
      state.color = color || "primary";
    },
    SET_TEXT(state, text) {
      state.text = text;
    },
    SET_TIMEOUT(state, timeout) {
      state.timeout = timeout;
    },
    SET_CLOSEABLE(state, closeable) {
      state.closeable = closeable;
    },
    SET_ACTION(state, action) {
      state.action = action;
    },
  },
  actions: {
    show(context, payload) {
      context.commit("SET_COLOR", payload.color);
      context.commit("SET_TEXT", payload.text);
      context.commit("SET_TIMEOUT", payload.timeout || DEFAULT_TIMEOUT);
      context.commit(
        "SET_CLOSEABLE",
        "closeable" in payload ? payload.closeable : true
      );
      context.commit("SET_ACTION", payload.action || null);
    },
    success(context, payload) {
      if (typeof payload === "string") {
        payload = { text: payload };
      }
      context.dispatch("show", { ...payload, color: "success" });
    },
    error(context, payload) {
      if (typeof payload === "string") {
        payload = { text: payload };
      }
      context.dispatch("show", { ...payload, color: "error" });
    },
    dismiss(context) {
      context.commit("SET_TEXT", null);
      context.commit("SET_ACTION", null);
    },
  },
};
