export default {
  namespaced: true,
  state: () => ({
    initialized: false,
    tenant: "",
    user: null,
    tokenExpiresAt: null,
    company: null,
    companies: null,
  }),
  mutations: {
    user(state, data) {
      state.tenant = data?.tenant;
      state.user = data?.user
        ? {
            id: data.user.id,
            username: data.user.username,
            language: data.language,
          }
        : undefined;
      state.tokenExpiresAt = data ? data.expires_at : null;
      state.initialized = true;
    },

    company(state, company) {
      if (!company) {
        state.company = null;
        return;
      }

      state.company = {
        id: company.id,
        cnpj: company.tax_id,
        name: company.name,
        fiscalName: company.fiscal_name,
        address: {
          street: company.address?.street,
          number: "" + (company.address?.number || "S/N"),
          additional: company.address?.additional,
          neighbourhood: company.address?.neighbourhood,
          city: {
            id: company.address?.city.id,
            state: company.address?.city.state_code,
          },
          zipcode: company.address?.zipcode,
        },
        phones: company.phones,
        fiscalRegime: company.fiscal_regime,
        stateTaxNumber: company.state_tax_number,
      };
    },
    companies(state, companies) {
      if ((companies || []).length === 0) {
        state.companies = companies;
        return;
      }

      state.companies = companies.map(({ id, tax_id: cnpj, name }) => ({
        id,
        cnpj,
        name,
      }));
    },
  },
  actions: {
    logout({ commit }) {
      commit("user", null);
      commit("company", null);
      commit("companies", null);
    },
    async getUser({ state }) {
      await new Promise((resolve) => {
        this.watch(
          (state) => state.auth.initialized,
          (initialized) => {
            initialized && resolve();
          },
          { immediate: true }
        );
      });

      return state.user;
    },
  },
};
