export class HttpError extends Error {
  constructor(httpStatus, errorCode, message, field = undefined) {
    let errMsg = [];
    errMsg.push(`StatusCode=${httpStatus}`);
    errMsg.push(`ErrorCode=${errorCode}`);
    if (field) {
      errMsg.push(`Field=${field}`);
    }
    errMsg.push(`Message=${message}`);

    super(errMsg.join(" "));
    this.name = this.constructor.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.status = httpStatus;
    this.code = errorCode;
    this.msg = message;
    this.field = field;
  }
}

export class ValidationError extends Error {
  constructor(field, message) {
    if (!message) {
      if (field) {
        message = field;
        field = null;
      } else {
        message = "An validation error happened";
      }
    }

    let errMsg = [];
    if (field) {
      errMsg.push(`Field=${field}`);
    }
    errMsg.push(`Message=${message}`);

    super(errMsg);
    this.name = this.constructor.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.field = field;
  }
}
