export default ($http) => ({
  async get(id) {
    let { data: nfe } = await $http.get(`/v1/nfes/${id}`);
    return {
      ...nfe,
      xml_url: `https://files.giox.com.br/nfe/${nfe.sefaz_id}.xml`,
      pdf_url: `https://files.giox.com.br/nfe/${nfe.sefaz_id}.pdf`,
    };
  },
  async list(params) {
    const { data } = await $http.get("/v1/nfes", { params });
    return data.items.map((nfe) => ({
      ...nfe,
      xml_url: `https://files.giox.com.br/nfe/${nfe.sefaz_id}.xml`,
      pdf_url: `https://files.giox.com.br/nfe/${nfe.sefaz_id}.pdf`,
    }));
  },
});
