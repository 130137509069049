<template>
  <number
    v-bind="$attrs"
    :format="{
      style: 'currency',
      currency: 'BRL',
    }"
  >
    <template v-if="hideSymbol" v-slot:currency />
    <template v-if="hideSymbol" v-slot:literal />
    <template v-if="hideDecimals" v-slot:decimal />
    <template v-if="hideDecimals" v-slot:fraction />
  </number>
</template>

<script>
import Number from "./Number";

export default {
  name: "Currency",
  components: {
    Number,
  },
  props: {
    currency: String,
    hideSymbol: Boolean,
    hideDecimals: Boolean,
  },
};
</script>
