import $store from "@/store";
import $sentry from "@/plugins/sentry";
import $api from "@/plugins/api";

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    params: {},
    list: {
      total: 0,
    },
  }),
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_PARAMS(state, params) {
      state.params = params;
    },
    SET_LIST(state, list) {
      state.list = list;
    },
  },
  actions: {
    async get(context, id) {
      try {
        return await $api.products.get(id);
      } catch (err) {
        switch (err?.code) {
          case "not_found":
            $store.dispatch(
              "notification/error",
              `Produto <strong>#${id}</strong> não encontrado`
            );
            break;
          default:
            $sentry.captureException(err);
            $store.dispatch(
              "notification/error",
              "Ocorreu um erro ao carregar o Produto 😢"
            );
        }
        throw err;
      }
    },
    async list(context, params) {
      try {
        context.commit("SET_LOADING", true);
        context.commit("SET_PARAMS", params);

        const resp = await $api.products.list({
          q: params.search ? `${params.search}` : undefined,
          per_page: params.perPage,
          cursor: params.cursor,
          sort: sort(params),
        });
        context.commit("SET_LIST", resp);

        return resp;
      } catch (err) {
        $sentry.captureException(err);
        $store.dispatch(
          "notification/error",
          "Ocorreu um erro ao carregar a lista de Produtos 😢"
        );
      } finally {
        context.commit("SET_LOADING", false);
      }
    },
  },
};

function sort({ sortBy, sortDesc }) {
  if (!sortBy) return;
  const field = Array.isArray(sortBy) ? sortBy[0] : sortBy;
  const desc = Array.isArray(sortDesc) ? sortDesc[0] : sortDesc;
  return (desc ? "-" : "") + field;
}
