import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/visao-geral",
  },
  {
    name: "login",
    path: "/login",
    component: require("@/views/Login.vue").default,
    meta: {
      public: true,
    },
  },
  {
    name: "logout",
    path: "/logout",
    component: require("@/views/Logout.vue").default,
    meta: {
      public: true,
    },
  },
  {
    name: "overview",
    path: "/visao-geral",
    component: loadView("Overview"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "nf-product-list",
    path: "/notas-fiscais/produto",
    component: loadView("NfProduct/List"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "nf-product-new",
    path: "/notas-fiscais/produto/nova",
    component: loadView("NfProduct/View"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "nf-product-view",
    path: "/notas-fiscais/produto/:id",
    component: loadView("NfProduct/View"),
    props: true,
    meta: {
      layout: "app",
    },
  },
  {
    name: "nf-customer-list",
    path: "/notas-fiscais/consumidor",
    component: loadView("NfCustomer/List"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "nf-customer-new",
    path: "/notas-fiscais/consumidor/nova",
    component: loadView("NfCustomer/View"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "nf-customer-view",
    path: "/notas-fiscais/consumidor/:id",
    component: loadView("NfCustomer/View"),
    props: true,
    meta: {
      layout: "app",
    },
  },
  {
    name: "purchase-list",
    path: "/notas-fiscais/recebidas",
    component: loadView("Purchase/List"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "purchase-view",
    path: "/notas-fiscais/recebidas/:id",
    component: loadView("Purchase/View"),
    props: true,
    meta: {
      layout: "app",
    },
  },
  {
    name: "product-list",
    path: "/cadastros/produtos",
    component: loadView("Records/Products/List"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "product-new",
    path: "/cadastros/produtos/novo",
    component: loadView("Records/Products/View"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "product-view",
    path: "/cadastros/produtos/:id",
    component: loadView("Records/Products/View"),
    props: true,
    meta: {
      layout: "app",
    },
  },
  {
    name: "customer-list",
    path: "/cadastros/clientes",
    component: loadView("Records/Customers/List"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "customer-new",
    path: "/cadastros/clientes/novo",
    component: loadView("Records/Customers/View"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "customer-view",
    path: "/cadastros/clientes/:id",
    component: loadView("Records/Customers/View"),
    props: true,
    meta: {
      layout: "app",
    },
  },
  {
    name: "supplier-list",
    path: "/cadastros/fornecedores",
    component: loadView("Records/Suppliers"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "courier-list",
    path: "/cadastros/transportadoras",
    component: loadView("Records/Couriers"),
    meta: {
      layout: "app",
    },
  },
  {
    name: "404",
    path: "*",
    component: require("@/views/404.vue").default,
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("setLoading", true);

  // load user before anything
  let user = await store.dispatch("auth/getUser");

  if (to.matched.some((record) => record.meta.public)) {
    return next();
  }
  if (user) {
    return next();
  }

  console.log("User not logged in, redirecting...");

  var query = {};
  if (to.fullPath !== "/") {
    // just because /login?redirect=%2F is ugly
    query.redirect = to.fullPath;
  }
  next({ name: "login", query });
});

router.afterEach(() => {
  store.dispatch("setLoading", false);
});

export default router;

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`);
}
