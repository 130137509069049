import store from "@/store";
import http from "@/plugins/axios";
import moment from "@/plugins/moment";
import sentry from "@/plugins/sentry";

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    nfes: [],
    nfces: [],
  }),
  getters: {
    nfes: (state) => state.nfes,
    nfces: (state) => state.nfces,
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setItems(state, { model, items }) {
      state[`${model}s`] = items;
    },
  },
  actions: {
    async fetch({ commit }, criteria) {
      commit("setLoading", true);

      const { month, model } = criteria;
      const startDate = moment(month).format("YYYY-MM-DD");
      const endDate = moment(month)
        .add(1, "months")
        .subtract(1, "seconds")
        .format("YYYY-MM-DD");

      let params = new URLSearchParams({
        model,
        issue_date: `${startDate}..${endDate}`,
        per_page: -1,
      });

      try {
        const { data } = await http.get(`/v1/nfes?${params}`);
        commit("setItems", { model, items: data.items });
      } catch (err) {
        commit("setItems", { model, items: [] });

        sentry.captureException(err);
        store.dispatch(
          "notification/error",
          "Ocorreu um erro ao carregar as Notas Fiscais 😢"
        );
      } finally {
        commit("setLoading", false);
      }
    },
  },
};
