import Vue from "vue";
import VueCurrencyInput from "vue-currency-input";

Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: null,
    allowNegative: false,
    distractionFree: {
      hideNegligibleDecimalDigits: true,
    },
  },
});
