export default ($http) => ({
  async get(id) {
    const { data } = await $http.get(`/v1/customers/${id}`);
    return data;
  },
  async list(params) {
    const { data } = await $http.get("/v1/customers", { params });
    return data;
  },
});
