<template>
  <i18n-n
    id="n"
    :value="showSign && isPositive ? negativeValue : realValue"
    :class="classes"
    v-bind="attrs"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template v-if="showSign && isPositive" v-slot:minusSign>+</template>
  </i18n-n>
</template>

<script>
export default {
  name: "Number",
  props: {
    value: [Number, String, Object],
    showSign: Boolean,
    positiveClass: [String, Array],
    negativeClass: [String, Array],
    maximumFractionDigits: Number,
  },
  computed: {
    realValue() {
      return Number(this.value);
    },
    negativeValue() {
      return this.realValue * -1;
    },
    isPositive() {
      return this.showSign && this.realValue > 0;
    },
    classes() {
      if (this.realValue > 0) {
        return this.positiveClass;
      } else if (this.realValue < 0) {
        return this.negativeClass;
      }
      return [];
    },
    attrs() {
      var attrs = Object.assign({}, this.$attrs);
      attrs.format = attrs.format || {};
      if (this.maximumFractionDigits) {
        attrs.format.maximumFractionDigits = this.maximumFractionDigits;
      }
      return attrs;
    },
  },
};
</script>
