<template>
  <v-navigation-drawer
    absolute
    temporary
    width="300"
    :value="value"
    @input="onInput"
  >
    <v-card elevation="0">
      <v-card-title>
        <v-img contain height="32" src="/img/logo.png" />
      </v-card-title>
    </v-card>

    <v-divider />

    <list nav :items="items" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import List from "@/components/List";

export default {
  name: "SideBar",
  components: { List },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["isStandalone"]),
    items() {
      let items = [
        { title: "Visão Geral", icon: "mdi-chart-bar", to: "/visao-geral" },
        { header: "Notas Fiscais" },
        {
          icon: "mdi-archive",
          title: "Notas Fiscais de Produto",
          to: "/notas-fiscais/produto",
        },
      ];
      if (this.isStandalone || process.env.NODE_ENV !== "production") {
        items.push({
          icon: "mdi-card-account-details",
          title: "Notas Fiscais de Consumidor",
          to: "/notas-fiscais/consumidor",
        });
      }
      items.push(
        {
          icon: "mdi-cart-arrow-down",
          title: "Notas Fiscais Recebidas",
          to: "/notas-fiscais/recebidas",
        },
        { header: "Cadastros" },
        {
          icon: "mdi-archive",
          title: "Produtos",
          to: "/cadastros/produtos",
        },
        {
          icon: "mdi-account-multiple",
          title: "Clientes",
          to: "/cadastros/clientes",
        },
        {
          icon: "mdi-dolly",
          title: "Fornecedores",
          to: "/cadastros/fornecedores",
        },
        {
          icon: "mdi-truck-fast",
          title: "Transportadoras",
          to: "/cadastros/transportadoras",
        },
        { header: "Menu Fiscal" },
        {
          icon: "mdi-card-account-details",
          title: "Identificação do PAF-NFC-e",
          value: "identity",
          listeners: {
            click: (v) => {
              this.$emit("update:fiscal-menu", v);
            },
          },
        },
        {
          icon: "mdi-file-multiple",
          title: "Registros do PAF-NFC-e",
          value: "records",
          listeners: {
            click: (v) => {
              this.$emit("update:fiscal-menu", v);
            },
          },
        },
        {
          icon: "mdi-account-box",
          title: "Vendas Identificadas pelo CPF/CNPJ",
          value: "sales",
          listeners: {
            click: (v) => {
              this.$emit("update:fiscal-menu", v);
            },
          },
        }
      );
      return items;
    },
  },
  methods: {
    onInput(v) {
      this.$emit("input", v);
    },
  },
};
</script>
