<template>
  <v-container>
    <v-row>
      <v-col>
        <v-img contain height="114" src="/img/logo-slogan.png" />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" xl="3" lg="5" md="6" sm="7">
        <v-card
          class="py-5 px-5 mb-5"
          :outlined="$vuetify.breakpoint.xsOnly"
          :elevation="$vuetify.breakpoint.xsOnly ? 0 : 12"
        >
          <v-card-title class="justify-center">
            Fazer login na sua conta
          </v-card-title>

          <v-expand-transition>
            <v-alert
              dense
              text
              type="error"
              v-show="error.showGlobal"
              v-text="error.global"
            />
          </v-expand-transition>

          <v-form ref="form" @submit.prevent="signIn">
            <v-card-text>
              <v-text-field
                :autofocus="!this.tenant"
                required
                clearable
                prepend-inner-icon="mdi-domain"
                label="Conta"
                autocomplete="organization"
                v-model="tenant"
                :rules="rules.required"
                :error-messages="error.tenant"
                @keydown="clearError"
                @click:clear="clearTenant"
              />

              <v-text-field
                :autofocus="!!this.tenant"
                required
                prepend-inner-icon="mdi-account"
                label="Usuário"
                autocomplete="username"
                v-model="username"
                :rules="rules.required"
                @keydown="error.showGlobal = false"
              />

              <v-text-field
                required
                prepend-inner-icon="mdi-lock"
                label="Senha"
                autocomplete="current-password"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :rules="rules.required"
                @keydown="error.showGlobal = false"
              />

              <div class="d-flex">
                <v-spacer />
                <a @click="forgotPassword">Esqueceu sua senha?</a>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn block type="submit" color="primary" :loading="signingIn">
                Fazer Login
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <company-select v-model="selectCompany" @change="companySelected" />
  </v-container>
</template>

<script>
import { HttpError } from "@/components/Error";
import { mapState } from "vuex";
import CompanySelect from "@/components/CompanySelect";
import store from "@/store";

export default {
  name: "Login",
  metaInfo() {
    return {
      title: "Login",
    };
  },
  components: { CompanySelect },
  data: () => ({
    tenant: "",
    username: "",
    password: "",
    signingIn: false,
    showPassword: false,
    rules: {
      required: [(val) => (val || "").length > 0 || "Este campo é obrigatório"],
    },
    error: {
      showGlobal: false,
      global: "",
      tenant: "",
    },
    selectCompany: false,
  }),
  computed: {
    ...mapState("auth", ["company", "companies"]),
  },
  watch: {
    tenant(v) {
      const companyId = this.$localStorage.get(`${v}:company`);
      if (companyId) {
        this.$http.defaults.headers.common["X-Company-Id"] = companyId;
      } else {
        delete this.$http.defaults.headers.common["X-Company-Id"];
      }
    },
    companies(v) {
      // After signIn the users' companies will be loaded, when we have a response
      this.signingIn = false;

      if ((v || []).length === 1) {
        this.companySelected(v[0]);
        return;
      }
      if (v !== null && !this.company) {
        this.selectCompany = true;
      }
    },
  },
  created() {
    let { conta } = this.$route.query;
    if (!conta) {
      conta = this.$localStorage.get("tenant", "");
    }
    this.tenant = conta;
  },
  methods: {
    clearError() {
      this.error.showGlobal = false;
      this.error.tenant = "";
    },
    clearTenant() {
      this.$localStorage.remove("tenant");
    },
    signIn() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.signingIn = true;

      this.$http
        .post("/v1/auth/session", {
          tenant: this.tenant,
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          const { data } = res;

          this.$localStorage.set("tenant", data.tenant);
          this.$store.commit("auth/user", data);
          if (data.company) {
            this.companySelected(data.company);
            return;
          }
        })
        .catch((err) => {
          if (err instanceof HttpError) {
            switch (err.code) {
              case "tenant_not_found":
                this.error.tenant = "Conta não encontrada";
                break;
              case "user_not_found":
                this.error.global = "Usuário/senha inválidos";
                this.error.showGlobal = true;
                break;
              default:
                this.error.global = "Ocorreu um erro na tentativa de login";
                this.error.showGlobal = true;
                this.$sentry.captureException(err, {
                  user: {
                    tenant: this.tenant,
                    username: this.username,
                    email: this.username + "@" + this.tenant,
                  },
                });
            }
          } else {
            console.error(err);
            this.$sentry.captureException(err);
          }
          this.signingIn = false;
        });
    },
    forgotPassword() {
      if (!this.$refs.form.validate()) {
        return false;
      }
    },
    companySelected(company) {
      if (!company) return;
      this.$store.commit("auth/company", company);

      this.$nextTick().then(() => {
        const redirect = this.$route.query.redirect;
        this.$router.push(redirect || "/");
      });
    },
  },

  async beforeRouteEnter(to, from, next) {
    let user = await store.dispatch("auth/getUser");
    if (!user) {
      next();
      return;
    }

    const { redirect } = to.query;
    next(redirect || "/");
  },
};
</script>
