<template>
  <div class="d-flex flex-column flex-grow-1">
    <v-card-title class="text-button">
      Registros do PAF-NFC-e
    </v-card-title>

    <v-divider />

    <v-card-text class="flex-grow-1">
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="6" sm="4">
              <v-select
                hide-details
                label="Estoque"
                :items="stockTypes"
                :rules="[notEmptyRule]"
                v-model="stockType"
              />
            </v-col>

            <v-col cols="12" sm="8" v-if="stockType === 'partial'">
              <product-field
                hide-details
                :rules="[notEmptyRule]"
                v-model="product"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <date-picker-field
                hide-details
                label="Data Inicial"
                :max="today"
                :rules="[notEmptyRule]"
                v-model="startDate"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <date-picker-field
                hide-details
                label="Data Final"
                :max="today"
                :rules="[notEmptyRule]"
                v-model="endDate"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions class="pb-4 justify-center">
      <v-btn depressed @click="$emit('close')">
        Fechar
      </v-btn>

      <v-btn color="success" :loading="loading" @click="download">
        <v-icon left>mdi-content-save-outline</v-icon>
        Download
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DownloadFile from "@/mixins/DownloadFile";
import Rules from "@/mixins/Rules";
import ProductField from "@/components/ProductField";
import DatePickerField from "@/components/DatePickerField.vue";

export default {
  name: "FiscalMenuRecords",
  mixins: [DownloadFile, Rules],
  components: {
    ProductField,
    DatePickerField,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      stockType: "total",
      startDate: this.$moment()
        .subtract(1, "months")
        .toISOString()
        .substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      product: null,
    };
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    stockTypes() {
      return [
        {
          value: "total",
          text: "Total",
        },
        {
          value: "partial",
          text: "Parcial",
        },
      ];
    },
    format() {
      return this.company?.address.city.state;
    },
    ...mapState("auth", ["company"]),
  },
  methods: {
    async download() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("update:loading", true);

      try {
        const { data } = await this.$http.post("/v1/fiscal/files/records", [], {
          params: {
            format: this.format,
            product: this.product?.id || undefined,
            period: `${this.startDate}..${this.endDate}`,
          },
        });

        const url = `/v1/fiscal/files/records/${data.id}`;
        const filename = `${this.company.cnpj}-registros.txt`;
        await this.downloadFileWhenReady(url, filename, data.id);
      } catch (err) {
        console.error(err);
        this.$sentry.captureException(err);

        this.notifyError("Ocorreu um erro ao efetuar o Download 😢");
      } finally {
        this.$emit("update:loading", false);
      }
    },
  },
};
</script>
