<template>
  <mask-text-field
    placeholder="(99) 9999-9999"
    :error-messages="errors"
    :label="label"
    :mask="['(##) ####-####', '(##) #####-####']"
    :prepend-inner-icon="!hideIcon ? 'mdi-phone' : ''"
    :required="required"
    :value="value"
    @blur="$v.value.$touch()"
    @input="$v.value.$touch()"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import {
  maxLength,
  minLength,
  numeric,
  requiredIf,
} from "vuelidate/lib/validators";
import MaskTextField from "@/components/MaskTextField";

export default {
  name: "PhoneField",
  components: {
    MaskTextField,
  },
  props: {
    hideIcon: Boolean,
    label: { type: String, default: "Telefone" },
    required: Boolean,
    value: String,
  },
  validations: {
    value: {
      required: requiredIf("required"),
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },
  },
  computed: {
    errors() {
      const errors = [];
      if (!this.$v.value.$dirty) return errors;
      !this.$v.value.required && errors.push("Campo obrigatório");
      (!this.$v.value.numeric ||
        !this.$v.value.minLength ||
        !this.$v.value.maxLength) &&
        errors.push(`Formato inválido`);
      return errors;
    },
  },
};
</script>
