<template>
  <v-app-bar
    app
    :elevate-on-scroll="$vuetify.breakpoint.xsOnly"
    :color="$vuetify.breakpoint.xsOnly ? '#ffffff' : '#f5f5f5'"
  >
    <v-app-bar-nav-icon v-if="simpleNavBar" @click="$emit('click:drawer')" />

    <v-toolbar-title
      v-if="showLogo"
      :class="{ 'ml-n2': simpleNavBar, 'ml-2 mr-6': !simpleNavBar }"
    >
      <v-img src="/img/logo.png" />
    </v-toolbar-title>

    <v-toolbar-items v-if="!simpleNavBar">
      <v-btn depressed active-class="primary--text" to="/visao-geral">
        <v-icon left>mdi-chart-bar</v-icon>
        Visão Geral
      </v-btn>

      <v-menu
        bottom
        offset-y
        open-on-hover
        max-width="300"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            :class="{
              'v-btn--active primary--text': routeStartWith('/notas-fiscais'),
            }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-cart-variant</v-icon>
            Notas Fiscais
          </v-btn>
        </template>

        <list :items="notasFiscaisItems" />
      </v-menu>

      <v-menu
        bottom
        offset-y
        open-on-hover
        max-width="300"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            :class="{
              'v-btn--active primary--text': routeStartWith('/cadastros'),
            }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-folder-open</v-icon>
            Cadastros
          </v-btn>
        </template>

        <list :items="recordsItems" />
      </v-menu>
    </v-toolbar-items>

    <v-spacer />

    <v-toolbar-items v-if="!simpleNavBar">
      <v-menu
        left
        bottom
        offset-y
        open-on-hover
        max-width="400"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed v-bind="attrs" v-on="on">
            <v-icon left>mdi-calculator</v-icon>
            Menu Fiscal
          </v-btn>
        </template>

        <list
          :items="fiscalItems"
          @click:item="$emit('update:fiscal-menu', $event)"
        />
      </v-menu>
    </v-toolbar-items>

    <v-tooltip v-if="deferredPrompt" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="deferredPrompt.prompt()">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>

      <span>Instalar Giox NF-e</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          href="http://suporte.gioxnfe.com.br"
          target="_blank"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>

      <span>Ajuda</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </template>

      <span>Configurações</span>
    </v-tooltip>

    <v-divider v-if="!simpleNavBar" vertical inset class="ml-3 mr-2" />

    <v-toolbar-items class="account">
      <v-menu
        bottom
        left
        offset-y
        transition="slide-transition"
        origin="top right"
        max-width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="showUsername"
            depressed
            color="#f5f5f5"
            class="no-overflow"
            max-width="300px"
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar class="secondary lighten-4" left>
              <v-icon size="32" color="white">
                mdi-account
              </v-icon>
            </v-avatar>

            <div
              class="d-flex flex-column align-start px-4"
              style="max-width: 150px"
            >
              <span>{{ username }}</span>

              <span
                class="text-truncate caption secondary--text text--lighten-2"
                style="width: 100%"
              >
                {{ companyName }}
              </span>
            </div>
          </v-btn>

          <v-btn v-else icon v-on="on">
            <v-icon>
              mdi-account
            </v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle
                class="font-weight-regular secondary--text text--lighten-2"
              >
                Conta:
              </v-list-item-subtitle>
              <v-list-item-title class="text-uppercase">
                {{ tenant }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!showUsername">
            <v-list-item-avatar>
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle
                class="font-weight-regular secondary--text text--lighten-2"
              >
                Logado como:
              </v-list-item-subtitle>
              <v-list-item-title class="text-uppercase">
                {{ username }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item-group
            color="primary"
            v-model="selectedCompanyId"
            @change="companyChanged"
          >
            <v-list-item
              v-for="(c, i) in companies"
              :key="i"
              :disabled="c.id === selectedCompanyId"
              :value="c.id"
            >
              <v-list-item-avatar>
                <v-icon v-if="c.id === selectedCompanyId">
                  mdi-office-building-marker
                </v-icon>
                <v-icon v-else>
                  mdi-office-building
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="c.name" />
                <v-list-item-subtitle
                  class="font-weight-regular secondary--text text--lighten-2"
                >
                  {{ c.cnpj | cnpj }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>

      <span>Sair</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FormatterFilter from "@/mixins/FormatterFilter";
import List from "@/components/List";

export default {
  name: "NavBar",
  mixins: [FormatterFilter],
  components: {
    List,
  },
  data: () => ({
    selectedCompanyId: null,
    deferredPrompt: null,
  }),
  computed: {
    ...mapGetters(["isStandalone"]),
    ...mapState("auth", ["tenant", "user", "company", "companies"]),
    username() {
      return this.user?.username;
    },
    companyName() {
      return this.company?.name || "Não logado";
    },
    showLogo() {
      return !this.$vuetify.breakpoint.xsOnly;
    },
    simpleNavBar() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showUsername() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    notasFiscaisItems() {
      let items = [
        {
          icon: "mdi-archive",
          title: "Notas Fiscais de Produto",
          to: "/notas-fiscais/produto",
        },
      ];
      if (
        this.isStandalone ||
        this.username === "suporte" ||
        process.env.NODE_ENV !== "production"
      ) {
        items.push({
          icon: "mdi-card-account-details",
          title: "Notas Fiscais de Consumidor",
          to: "/notas-fiscais/consumidor",
        });
      }

      items.push(
        {
          icon: "mdi-wrench",
          title: "Notas Fiscais de Serviço",
          subtitle: "Não disponível",
          disabled: true,
        },
        { divider: true },
        {
          icon: "mdi-cart-arrow-down",
          title: "Notas Fiscais Recebidas",
          to: "/notas-fiscais/recebidas",
        }
      );
      return items;
    },
    recordsItems() {
      return [
        {
          icon: "mdi-archive",
          title: "Produtos",
          to: "/cadastros/produtos",
        },
        {
          icon: "mdi-wrench",
          title: "Serviços",
          subtitle: "Não disponível",
          disabled: true,
        },
        { divider: true },
        {
          icon: "mdi-account-multiple",
          title: "Clientes",
          to: "/cadastros/clientes",
        },
        {
          icon: "mdi-dolly",
          title: "Fornecedores",
          to: "/cadastros/fornecedores",
        },
        {
          icon: "mdi-truck-fast",
          title: "Transportadoras",
          to: "/cadastros/transportadoras",
        },
      ];
    },
    fiscalItems() {
      return [
        {
          icon: "mdi-card-account-details",
          title: "Identificação do PAF-NFC-e",
          value: "identity",
        },
        {
          icon: "mdi-file-multiple",
          title: "Registros do PAF-NFC-e",
          value: "records",
        },
        {
          icon: "mdi-account-box",
          title: "Vendas Identificadas pelo CPF/CNPJ",
          value: "sales",
        },
        {
          icon: "mdi-database-arrow-left",
          title: "Requisições Externas Registradas",
          subtitle: "Não disponível",
          disabled: true,
        },
        {
          icon: "mdi-glass-cocktail",
          title: "Mesas Abertas",
          subtitle: "Não disponível",
          disabled: true,
        },
      ];
    },
  },
  watch: {
    "company.id": {
      handler(v) {
        this.selectedCompanyId = v;
      },
      immediate: true,
    },
  },
  methods: {
    companyChanged() {
      if (!this.selectedCompanyId) return;

      const company = this.companies.find(
        (c) => this.selectedCompanyId == c.id
      );
      if (company) {
        this.$emit("change:company", company);
      }
    },
    routeStartWith(v) {
      return this.$route.path.startsWith(v);
    },
    logout() {
      this.$router.push({ path: "/logout" });
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
};
</script>

<style scoped lang="sass">
.v-app-bar.v-app-bar--hide-shadow
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important

.v-toolbar__items.account
  @media #{map-get($display-breakpoints, 'xs-only')}
    height: 48px

.v-list-item.v-list-item--active
  &::before
    opacity: 0

  .v-list-item__subtitle
    color: inherit !important

.v-btn.no-overflow::v-deep >
  .v-btn__content
    width: 100%
</style>
