<template>
  <div class="d-flex flex-column flex-grow-1">
    <v-card-title class="text-button">
      Identificação do PAF-NFC-e
    </v-card-title>

    <v-divider />

    <v-card-text class="flex-grow-1">
      <v-container>
        <v-row no-gutters>
          <v-col cols="9">
            <v-text-field
              readonly
              hide-details
              label="Nome Comercial"
              :value="appName"
            />
          </v-col>

          <v-col offset="1" cols="2">
            <v-text-field
              readonly
              hide-details
              label="Versão"
              :value="appVersion"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6">
            <cpf-cnpj-field readonly hide-details :type="type" :value="cnpj" />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              readonly
              hide-details
              label="Razão Social"
              :value="fiscalName"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6">
            <phone-field readonly hide-icon hide-details :value="phone" />
          </v-col>

          <v-col offset="1" cols="5">
            <v-text-field
              readonly
              hide-details
              label="Contato"
              :value="contact"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="9">
            <v-text-field hide-details label="Rua" :value="address.street" />
          </v-col>

          <v-col offset="1" cols="2">
            <v-text-field hide-details label="Número" :value="address.number" />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="4" sm="3">
            <v-text-field
              hide-details
              label="Bairro"
              :value="address.neighbourhood"
            />
          </v-col>

          <v-col offset="1" cols="7" sm="4">
            <v-text-field hide-details label="Cidade" :value="city" />
          </v-col>

          <v-col offset-sm="1" cols="5" sm="3">
            <mask-text-field
              hide-details
              label="CEP"
              mask="##.###-###"
              :value="address.zipcode"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions class="pb-4 justify-center">
      <v-btn depressed @click="$emit('close')">
        Fechar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import CpfCnpjField from "@/components/CpfCnpjField";
import PhoneField from "@/components/PhoneField";
import MaskTextField from "@/components/MaskTextField";

export default {
  name: "FiscalMenuIdentity",
  components: {
    CpfCnpjField,
    PhoneField,
    MaskTextField,
  },
  data: () => ({
    appName: "Giox NFe",
    appVersion: "1.0.0",
    type: "company",
    cnpj: "19692725000116",
    fiscalName: "GIOX TECNOLOGIA LTDA",
    phone: "48998288115",
    contact: "Guilherme",
    address: {
      zipcode: "88034001",
      street: "Rod. Admar Gonzaga",
      number: "3771",
      neighbourhood: "Itacorubi",
      city: "Florianópolis",
      stateCode: "SC",
    },
  }),
  computed: {
    city() {
      return this.address.city + " / " + this.address.stateCode;
    },
  },
};
</script>
