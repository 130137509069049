<template>
  <v-menu
    offset-y
    transition="scale-transition"
    max-width="290px"
    min-width="290px"
    v-model="showMenu"
    :close-on-content-click="false"
    :disabled="readonly"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        type="text"
        :value="formattedDate"
        @input="changed"
        v-bind="$attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      no-title
      :type="type"
      :locale="$store.state.language"
      :min="min"
      :max="max"
      :value="value"
      @input="changed"
    />
  </v-menu>
</template>

<script>
export default {
  name: "DatePickerField",
  props: {
    max: String,
    min: String,
    readonly: Boolean,
    type: { type: String, default: "date" },
    value: [Number, String],
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    formattedDate() {
      if (this.value) {
        if (this.type === "month") {
          return this.$moment(this.value).format("MMMM / Y");
        }
        return this.$moment(this.value).format("L");
      }
      return null;
    },
  },
  methods: {
    changed(v) {
      this.showMenu = false;
      if (v !== this.value) {
        this.$emit("input", v);
        this.$emit("change", v);
      }
    },
  },
};
</script>
