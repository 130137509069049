import FiscalService from "@/services/FiscalService";
import ZipcodeService from "@/services/ZipcodeService";

export default {
  state: () => ({
    loading: true,
    displayMode: null,
    states: {
      BR: [],
    },
    cests: [],
    cfops: [],
    ncms: [],
  }),
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_DISPLAY_MODE(state, displayMode) {
      state.displayMode = displayMode;
    },
    SET_COUNTRY_STATES(state, { country, states }) {
      state.states[country] = states;
    },
    SET_CESTS(state, cests) {
      state.cests = cests;
    },
    SET_CFOPS(state, cfops) {
      state.cfops = cfops;
    },
    SET_NCMS(state, ncms) {
      state.ncms = ncms;
    },
  },
  getters: {
    isStandalone: (state) => state.displayMode !== "browser",
    countryStates: (state) => (country) => state.states[country] || [],
    cest: (state) => (cest) =>
      (state.cests || []).find(({ code }) => code == cest),
    cfop: (state) => (cfop) =>
      (state.cfops || []).find(({ code }) => code == cfop),
    ncm: (state) => (ncm) => (state.ncms || []).find(({ code }) => code == ncm),
  },
  actions: {
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
    setDisplayMode({ commit }, displayMode) {
      commit("SET_DISPLAY_MODE", displayMode);
    },

    async fetchCountryStates({ commit, state }, country) {
      if (state.states[country] && state.states[country].length) {
        return;
      }

      var { items: states } = await ZipcodeService.getCountryStates(country);
      commit("SET_COUNTRY_STATES", { country, states });
    },

    async fetchCESTs({ commit, state }) {
      if (state.cests && state.cests.length) {
        return;
      }

      var { items: cests } = await FiscalService.getCESTs();
      commit("SET_CESTS", cests);
    },

    async fetchCFOPs({ commit, state }) {
      if (state.cfops && state.cfops.length) {
        return;
      }

      var { items: cfops } = await FiscalService.getCFOPs();
      commit("SET_CFOPS", cfops);
    },

    async fetchNCMs({ commit, state }) {
      if (state.ncms && state.ncms.length) {
        return;
      }

      var { items: ncms } = await FiscalService.getNCMs();
      commit("SET_NCMS", ncms);
    },
  },
};
